<template>
  <div>
    <div
      ref="taxiFormRef"
      class="repeater-form"
      :style="{ height: trHeight }"
    >
      <b-row
        v-for="(taxi, index) in tour.taxis"
        :key="index"
        ref="taxiItemForm"
        class="pb-2"
      >
        <b-col cols="12">
          <div class="d-flex border rounded">
            <b-row class="flex-grow-1 p-2">
              <b-col lg="4">
                <validation-provider
                  #default="{ errors }"
                  name="Voucher"
                  rules="required"
                >
                  <b-form-group
                    label="Voucher"
                    label-for="voucher"
                  >
                    <b-form-input
                      id="voucher"
                      v-model="taxi.voucherNum"
                      type="number"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col lg="4">
                <validation-provider
                  #default="{ errors }"
                  name="Taxi"
                  rules="required"
                >
                  <b-form-group
                    label="Taxi"
                    label-for="taxi"
                  >
                    <v-select
                      :clearable="false"
                      :options="taxiList"
                      @input="(taxi) => taxiSelected(index, taxi)"
                      label="licenceNum"
                      v-model="taxi.taxi"
                    >
                      <template #option="{ firstName, lastName, licenceNum }">
                        <span>
                          <span class="font-weight-bolder">{{ licenceNum }}</span>
                          -
                          <small>{{ `${firstName} ${lastName}` }}</small>
                        </span>
                      </template>

                      <template #selected-option="{ firstName, lastName, licenceNum }">
                        <span>
                          <span class="font-weight-bolder">{{ licenceNum }}</span>
                          -
                          <small>{{ `${firstName} ${lastName}` }}</small>
                        </span>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col lg="4">
                  <b-form-group
                    label="Vehicle"
                    label-for="vehicle"
                  >
                    <v-select
                      :clearable="false"
                      :options="taxiVehiclesList[index]"
                      @input="(vehicle) => vehicleSelected(index, vehicle)"
                      label="model"
                      v-model="taxi.vehicle"
                    >
                      <template #no-options>Please, select the taxi</template>
                      <template #option="{ model, regNum }">
                        <span>
                          <span class="font-weight-bolder">{{ regNum }}</span>
                          -
                          <small>{{ model }}</small>
                        </span>
                      </template>

                      <template #selected-option="{ model, regNum }">
                        <span>
                          <span class="font-weight-bolder">{{ regNum }}</span>
                          -
                          <small>{{ model }}</small>
                        </span>
                      </template>
                    </v-select>
                  </b-form-group>
              </b-col>
            </b-row>
            <div
              class="pt-50 pr-50"
              v-if="(tour.taxis.length > 1)"
            >
              <feather-icon
                size="16"
                icon="XIcon"
                class="cursor-pointer"
                @click="removeTaxi(index)"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-button
      size="sm"
      variant="primary"
      @click="addNewTaxi"
    >
      Add Taxi
    </b-button>
  </div>
</template>
<script>
import { BButton, BCol, BFormInput, BFormGroup, BRow } from 'bootstrap-vue';
import { computed, nextTick, onMounted, onUnmounted, ref } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import store from '@/store';
import useTourMultipleUtils from '@/modules/frontier-tour/composables/useTourMultipleUtils';
import useTransition from '@/composables/useTransition';
import vSelect from 'vue-select';

export default {
  setup() {
    const { trAddHeight, trHeight, trSetHeight, trTrimHeight } = useTransition();
    const { getTourFees, storeTour, updateTaxiRate } = useTourMultipleUtils();

    const taxiList = store.getters['taxi/taxiList'];

    const tour = computed(() => store.getters['tourMultiple/tour']);

    const taxiFormRef = ref(null);
    const taxiItemForm = ref(null);
    const taxiVehiclesList = ref([]);

    onMounted(() => { initTrHeight(); });
    onUnmounted(() => { window.removeEventListener('resize', initTrHeight); });
    window.addEventListener('resize', initTrHeight);

    const initTrHeight = () => {
      trSetHeight(null);
      nextTick(() => { trSetHeight(taxiFormRef.value.scrollHeight); });
    };

    const addNewTaxi = () => {
      taxiFormRef.value.style.overflow = 'hidden';
      tour.value.taxis.push({ voucherNum: null, taxi: null, taxiRate: 0.0, vehicle: null });
      storeTour(tour.value);

      nextTick(() => {
        trAddHeight(taxiItemForm.value[0].offsetHeight);
        setTimeout(() => { taxiFormRef.value.style.overflow = null; }, 350);
      });
    };

    const removeTaxi = (index) => {
      const { taxiCommission } = getTourFees(tour.value);

      tour.value.taxis.splice(index, 1);
      tour.value.taxis = updateTaxiRate(tour.value.taxis, taxiCommission);

      storeTour(tour.value);
      trTrimHeight(taxiItemForm.value[0].offsetHeight);
    };

    const taxiSelected = (index, taxi) => {
      if (taxi !== null) {
        const mainVehicle = taxi.vehicles.find((vehicle) => vehicle.isMainVehicle);
        const vehicle = (mainVehicle) ? mainVehicle : taxi.vehicles[0];
        const { taxiCommission } = getTourFees(tour.value);

        taxiVehiclesList.value[index] = [...taxi.vehicles];

        tour.value.taxis[index].taxi = taxi;
        tour.value.taxis[index].vehicle = vehicle;
        tour.value.taxis = updateTaxiRate(tour.value.taxis, taxiCommission);
      } else {
        tour.value.taxis[index].taxi = null;
        tour.value.taxis[index].vehicle = null;
        tour.value.taxis[index].taxiRate = 0.0;
      }

      storeTour(tour.value);
    };

    const vehicleSelected = (index, vehicleSelected) => {
      tour.value.taxis[index].vehicle.id = vehicleSelected.id;
      storeTour(tour.value);
    };

    return {
      tour,
      taxiFormRef,
      taxiItemForm,
      taxiList,
      taxiVehiclesList,
      trHeight,

      //Method
      addNewTaxi,
      removeTaxi,
      taxiSelected,
      vehicleSelected,
    };
  },
  components: {
    BButton,
    BCol,
    BFormInput,
    BFormGroup,
    BRow,
    ValidationProvider,
    vSelect,
  },
};
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {

  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
