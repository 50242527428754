<template>
  <section
    class="invoice-add-wrapper"
    v-if="dataLoaded"
  >
    <b-row class="invoice-add">
      <b-col lg="8">
        <validation-observer ref="tourForm">
          <b-form @submit.prevent>
            <b-card
              no-body
              class="invoice-preview-card"
            >
              <b-card-body class="invoice-padding pb-0">
                <tour-date-price />

                <tour-passenger />
              </b-card-body>

              <b-card-body class="invoice-padding">
                <tour-voucher-taxi />
              </b-card-body>

            </b-card>
          </b-form>
        </validation-observer>
      </b-col>

      <b-col lg="4">
        <tour-multiple-summary-card @submitTour="createNewTour" />

      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BCard, BCardBody, BCol, BForm, BFormCheckbox, BRow } from 'bootstrap-vue';
import { computed, ref } from '@vue/composition-api';
import { required } from '@validations';
import { ValidationObserver } from 'vee-validate';
import router from '@/router';
import store from '@/store';
import TourMultipleSummaryCard from '@/modules/frontier-tour/components/multiple/TourMultipleSummaryCard.vue';
import TourVoucherTaxi from '@/modules/frontier-tour/components/multiple/TourVoucherTaxi.vue';
import TourDatePrice from '@/modules/frontier-tour/components/multiple/TourDatePrice.vue';
import TourPassenger from '@/modules/frontier-tour/components/multiple/TourPassenger.vue';
import useTaxi from '@/modules/taxi/composables/useTaxi';
import useUtils from '@/composables/useUtils';
import useGlobalSettings from '@/modules/setting/composables/useGlobalSettings';
import useTour from '@/modules/frontier-tour/composables/useTour';

export default {
  setup() {
    const { getTaxiList } = useTaxi();
    const { convertDateToUTC } = useUtils();
    const { getGlobalSetting } = useGlobalSettings();
    const { createMultipleTour } = useTour();

    const tourData = computed(() => store.getters['tourMultiple/tour']);
    const tourForm = ref(null);
    const globalSettings = ref(null);
    const dataLoaded = ref(false);

    const createNewTour = () => {
      const tour = store.getters['tourMultiple/tour'];
      tourForm.value.validate().then(async (valid) => {
        if (!valid) return;

        const result = await createMultipleTour({
          ...tour,
          tourDateUtc: convertDateToUTC(tour.tourDateUtc),
        });

        if (result === null) return;

        router.push({ name: 'frontier-tour-list' });
      });
    };

    const initData = async () => {
      store.commit('tourMultiple/resetTour');
      globalSettings.value = await getGlobalSetting();
      await getTaxiList();
    };

    initData().then(_ => {
      dataLoaded.value = true;
    });

    return {
      // Reactive
      tourData,
      tourForm,
      dataLoaded,

      // Method
      createNewTour,

      // Validator
      required,
    };
  },
  components: {
    BCard,
    BCardBody,
    BCol,
    BForm,
    BFormCheckbox,
    BRow,
    TourMultipleSummaryCard,
    TourVoucherTaxi,
    TourDatePrice,
    TourPassenger,
    ValidationObserver,
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.taxi-vehicle-select .vs__selected {
  width: 95%;
}

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {

  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
