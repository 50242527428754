<template>
  <section>
    <div class="d-flex flex-sm-row flex-column justify-content-between">
      <b-col sm="6">
        <div class="d-flex align-items-center">
          <validation-provider
            #default="{ errors }"
            name="Tour Date"
            rules="required"
          >
            <b-form-group
              label="Tour Date"
              label-for="tour-date"
            >
              <flat-pickr
                v-model="tour.tourDateUtc"
                class="form-control"
                :state="errors.length > 0 ? false : null"
                @input="storeTourData"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </div>
      </b-col>

      <b-col sm="6">
        <div class="d-flex align-items-center justify-content-sm-end">
          <validation-provider
            #default="{ errors }"
            name="Total Taxi"
            rules="required"
          >
            <b-form-group
              label="Total Taxi"
              label-for="total-taxi-fee"
            >
              <b-input-group prepend="£">
                <b-form-input
                  @input="updateTourFees"
                  id="total-taxi-fee"
                  v-model="tour.taxiTotalFee"
                  type="number"
                />
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </div>
      </b-col>
    </div>
  </section>
</template>

<script>
import { BCol, BFormGroup, BFormInput, BInputGroup, BInputGroupPrepend } from 'bootstrap-vue';
import { computed } from '@vue/composition-api';
import { ValidationProvider } from 'vee-validate';
import flatPickr from 'vue-flatpickr-component';
import store from '@/store';
import useTourMultipleUtils from '@/modules/frontier-tour/composables/useTourMultipleUtils';
import vSelect from 'vue-select';
import useUtils from "@/composables/useUtils";

export default {
  setup() {
    const { convertUtcToLocalDate } = useUtils();
    const { getTourFees, storeTour, updateTaxiRate } = useTourMultipleUtils();

    const tour = computed(() => store.getters['tourMultiple/tour']);

    const formatTourDates = () => {
      tour.value.tourDateUtc = (tour.value.tourDateUtc !== null)
        ? convertUtcToLocalDate(tour.value.tourDateUtc)
        : new Date();
    };

    const storeTourData = () => {
      storeTour(tour.value);
    };

    const updateTourFees = () => {
      const {
        accountCommission,
        juraAccCommission,
        taxiCommission,
        tourTotalFee } = getTourFees(tour.value);

      tour.value.accountCommission = accountCommission;
      tour.value.juraAccCommission = juraAccCommission;
      tour.value.tourTotalFee = tourTotalFee;
      tour.value.taxiTotalFee = taxiCommission;
      tour.value.taxis = updateTaxiRate(tour.value.taxis, taxiCommission);

      storeTour(tour.value);
    }

    formatTourDates();
    flatpickr.setDefaults({ dateFormat: 'd/m/Y H:i', enableTime: true });

    return {
      // Reactive
      tour,

      //Method
      storeTourData,
      updateTourFees,
    };
  },
  components: {
    BCol,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    flatPickr,
    ValidationProvider,
    vSelect,
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}

.tour-type-select {
  width: 100%;
  max-width: 197px;
}
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/pages/app-invoice.scss';
@import '~@core/scss/base/components/variables-dark';

.tour-header {
  .title {
    width: 115px;
  }
}

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {

  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
</style>
